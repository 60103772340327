<div class="container" style="margin-top: 10vh">
  <div class="row justify-content-center">
    <div class="col-sm-6 mx-5 p-4" style="background-color: white">
      <div class="d-flex justify-content-center">
        <img src="assets/sealLine.png" alt="" class="img-fluid" />
      </div>
      <div class="d-flex justify-content-center">
        <p style="font-size: larger">GESTIÓN DE ARCHIVOS</p>
      </div>
      <div class="d-flex justify-content-center">
        <p
          class="px-5"
          style="background-color: #51a8db; color: white; font-size: larger"
        >
          INICIAR SESIÓN
        </p>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
        <div class="form-group">
          <input
            class="text-center"
            type="email"
            id="emailInput"
            placeholder="Correo electrónico"
            style="width: 100%"
            formControlName="email"
          />
        </div>
        <div class="form-group">
          <input
            class="text-center"
            type="password"
            id="passwordInput"
            placeholder="Contraseña"
            style="width: 100%"
            formControlName="password"
          />
        </div>
        <div class="d-flex justify-content-center">
          <ngx-recaptcha2
            #captchaElem
            siteKey="6Lf9dX4cAAAAAPGulajpDBZ_gn_FCg_z8NV8FGRH"
            formControlName="recaptcha"
          >
          </ngx-recaptcha2>
        </div>
        <div class="alert alert-danger mt-1" role="alert" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
        <div class="d-flex justify-content-center">
          <button
            type="submit"
            class="btn btn-outline-secondary btn-lg w-50 mt-2"
          >
            Entrar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
