import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../interfaces/user';
import { Credentials } from '../interfaces/credentials';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  apiServer = 'https://seal-platform-gt.herokuapp.com/';

  constructor(
    private httpClient: HttpClient,
  ) {}

  signIn(user: User) {
    return this.httpClient.post<Credentials>(
      this.apiServer + 'authentication',
      user
    );
  }
}
