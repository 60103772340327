import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LoginService } from './services/login.service';
import { User } from './interfaces/user';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'seal-gt-login';
  loginForm;
  errorMessage;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) {
    this.loginForm = this.formBuilder.group({
      email: '',
      password: '',
      recaptcha: null,
    });
  }

  ngOnInit() {}

  onSubmit(loginData: any) {
    if (loginData.email && loginData.password) {
      if (loginData.recaptcha) {
        let user: User = {
          email: loginData.email,
          password: loginData.password,
        };
        this.loginService.signIn(user).subscribe(
          (results) => {
            window.top.location.href =
              'https://www.seal.com.gt/platform/' +
              results.id +
              '/' +
              results.token;
          },
          () => {
            this.errorMessage = 'Credenciales inválidas';
          }
        );
      } else {
        this.errorMessage = 'Demuestra que no eres un robot';
      }
    } else {
      this.errorMessage = 'Ingresa tu correo y contraseña';
    }
  }
}
